const en_US = {
    //components
    "XiaDanTiXing":"Order reminder",
    "NH_GDDBH_":"Hello, this order contains “estimated price” goods, you need to wait for the customer to confirm the quotation before paying, are you sure you want to place the order",
    "QueRenXiaDan":"Confirm order",
    "WoZaiKanKan":"Let me see",

    "Miao": "Second",

    //ChatRoom
    "ZBZCBQFH": "Emoji are not supported yet",
    "FaSongLianJie": "Send link",
    "YiChangXiaoXi": "Exception message",
    "QingShuRu": "please enter",
    "FaSong": "send",

    //DateSelector
    "DQSJXYKSSJ": "The current time is less than the start time",
    "DQSJCGJSSJ": "Current time exceeds end time",
    "QuXiao": "cancel",
    "NianYueRi": "year month day",
    "ShiFen": "Time",
    "QueDing": "determine",
    "Nian": "year",
    "Yue": "month",
    "Ri": "day",
    "Shi": "Time",
    "Fen": "Minute",

    //goods-list-card
    "Qi": "Up",

    //goods-promotion
    "1GeCuXiao": "1 promotion",
    "ChaKanZengPin": "View giveaway",
    "JHDJSHS_1T": "Before the end of the event: {d}days{h}hours{m}minutes{s} seconds",
    "YHGZ_ZPBKD": "Preferential rules: gifts cannot be obtained by stacking",
    "YHGZ_AZZGM": "Discount rules: enjoy the discount according to the final purchase quantity",
    "GuanBi": "shut down",

    //group-filtrate
    "QuanBu": "All",
    "ZWXGSJ": "No relevant data",
    "ZuiDiJia": "Lowest price",
    "ZuiGaoJia": "Highest price",
    "LeiXing": "Types of",
    "PinPai": "Brand",
    "BiaoQian": "label",
    "JiaGeQuJian": "The price range",
    "SPMC_GG_BH_": "Product name / specifications / serial number / initials",
    "ZuHeShaiXuan": "Combinatorial screening",
    "ChongZhi_CanShu": "Reset",

    //image-upload
    "ZDKSC1ZTP": "You can upload up to {num} images",
    "ZYXSCTP": "Only upload pictures",
    "SCTPDXBYXC": "Uploaded image size should not exceed {size}",
    "QBYCFSCTP_": "Please do not upload pictures repeatedly, or make sure the picture name is unique",
    "ShangChuanChengGong": "Uploaded successfully",
    "ZDKSC1Z": "You can upload up to {num}",

    //keyboard
    "CCSLZDXZ": "Maximum number exceeded",
    "QiDingLiang": "MOQ",
    "ShouQi": "Collapse",
    "ShanChu": "Delete",

    //MultiPicker
    "FanHui": "Return",

    //order-cart
    "Dai": "generation",
    "YiQuXiao": "Cancelled",
    "ZengPin": "Giveaway",
    "1Zhong": "{num} species",
    "1Jian": "1 item",
    "HeJi": "Total",
    "DaiFu": "To be paid",

    //order-promotion
    "_DDM1YZ1J_": "[Gift {presentNum} for orders over {targetPrice} USD]",
    "_DDM1YLJ1Y_": "[{TargetPrice} USD will be reduced {USD}]",
    "_DDM1YLX1Z_": "[Enjoy {discount} discount on orders over {targetPrice} USD]",
    "DingDanCuXiao": "Order promotion",

    //page-ad
    "DLCKJG": "Log in to view prices",

    //page-floating
    "DianPuZhuYe": "Store Home",
    "GouWuChe": "Shopping cart",
    "ZaiXianKeFu": "online service",
    "GeRenZhongXin": "Personal center",
    "LianXiDianHua": "contact number",
    "FanHuiDingBu": "Back to top",

    //page-null
    "ZBDYML": "Page not found",
    "KNWZCW_HZY": "The URL may be wrong, or the page may be deleted",
    "FanHuiShangJi": "Return to superior",
    "FanHuiShouYe": "Back to top",

    //product-edit
    "SPQDBNWK": "Product list cannot be empty",
    "WoZhiDaoLe": "I know",
    "SFQRSCSP": "Are you sure to delete the product",
    "QueRen": "Confirm",
    "ZYXSRSZBQB": "Only numbers are allowed and no more than 2 decimal places",
    "ShangPinDanJia": "Commodity price",
    "YSPYJ": "Original price",
    "QSRZDJG": "Please enter the specified price",
    "ShuLiang": "Quantity",
    "DanWei": "Unit",
    "DanJia": "Unit price",
    "BeiZhu": "Remarks",
    "YiChuShangPin": "Remove product",
    "XiuGai": "modify",

    //yunshl
    "TPSLCCYXSZ": "The number of pictures exceeds the allowable value!",
    "WJBDDY5M_QC": "The file should not be larger than 5m, please choose again",
    "TPSCWC": "Picture upload completed",

    //BusinessFilter
    "_DGSLM1JZ1J": "[Gift {presentNum} um {num} if the order quantity is full]",
    "_M1JLXMJ1Y_": "[Enjoy {price} dollars for every {num} item]",
    "_DHJJZ1Y_": "[Order price reduced to {price} USD]",
    "_M1JLXMJ1Z_": "[Enjoy {discount} discount for every {num}} item]",
    "_YDHJD1Z_": "[The original order price is discounted by {discount}]",
    "YuJi": "Expected",
    "MMYDDR": "MM month DD day",
    "KaiShiFaHuo": "Start shipping",
    "_DGSLM1Z1_": "[{Num} gift {presentNum} when order quantity is full]",
    "_M1LXM1Y_": "[Every {num} USD can be enjoyed for every {price}]",
    "_M1LXM1Z_": "[Limit every {discount} discount with full {num}]",
    "DaiQueRen": "To be confirmed",
    "DaiFaHuo": "To be delivered",
    "DaiShouHuo": "To be received",
    "YiWanCheng": "Completed",
    "DaiFuKuan": "Pending payment",
    "YiFuKuan": "Paid",
    "YiZuoFei": "Abolished",
    "DaiShenHe": "Pending review",
    "DaiHeXiao": "To be written off",
    "YiHeXiao": "Written off",
    "XianXiaZhiFu": "Offline payments",
    "ZhiNengDaoZhangShiBie": "线下汇款(智能到账识别)",
    "WeiXinZhiFu": "WeChat Pay",
    "ZFBZF": "Pay with Ali-Pay",
    "YuEZhiFu": "Balance payment",
    "HuoDaoFuKuan": "Cash on delivery",
    "DaiTuiHuo": "Pending return",
    "DaiTuiKuan": "Pending refund",
    "ChuangJianDingDan": "Create Order",
    "BianJiDingDan": "Edit order",
    "TiJiaoDingDan": "Submit orders",
    "DingDanShenHe": "Order review",
    "CaiWuShenHe": "Financial Audit",
    "BoHui": "turn down",
    "TianJiaShouKuan": "Add payment",
    "ZaiXianZhiFu": "Online payments",
    "ShouKuanShenHe": "Collection review",
    "ShouKuanZuoFei": "Invalid receipt",
    "ChuKu": "Outbound",
    "ChuKuShenHe": "Outbound audit",
    "FaHuo": "Ship",
    "TianJiaWuLiu": "Add logistics",
    "XiuGaiWuLiu": "Modify logistics",
    "ChuKuZuoFei": "Out of stock",
    "ShouHuo": "Receipt",
    "DingDanHeZhun": "Order approval",
    "QuXiaoDingDan": "Cancel order",
    "CJTHD": "Create a return order",
    "DaYin": "print",
    "DaoChu": "Export",
    "DingDanWanCheng": "Order completed,",
    "QiangZhiWanCheng": "Compulsory completion",
    "DingDanZuoFei": "Order void",
    "MaiZeng": "Buy gifts",
    "ZhiJiang": "fall",
    "DaZhe": "Discount",
    "YuShou": "Pre-sale",
    "DingDanManZeng": "Free orders",
    "DingDanManJian": "Order full reduction",
    "DingDanManZhe": "Full discount",
    "XinRenQuan": "Newcomer coupon",
    "ManJianQuan": "Full coupon",
    "ShangPinQuan": "Merchandise coupon",
    "DaiJieSuan": "To be settled",
    "YiJieSuan": "Settled",
    "QuanBuShangPin": "All products",
    "TuiJianShangPin": "Recommended products",
    "XinPin": "New product",
    "ReXiaoShangPin": "Hot Products",
    "ChangYongShangPin": "Commonly used goods",

    //BusinessUtil
    "DaiShouKuan": "Pending payment",
    "BuFenShouKuan": "Partial collection",
    "YiShouKuan": "paid for",
    "BXYFP": "No invoice required",
    "ZZSPTFP": "VAT general invoice",
    "ZZSZYFP": "VAT invoice",
    "QiTaFaPiao": "Other invoices",
    "XianXia": "Offline",
    "WeiXin": "WeChat",
    "ZhiFuBao": "Alipay",
    "YuCunKuan": "Pre-deposit",
    "XianXiaZhuangZhang": "Offline transfer",
    "XianShangZhiFu": "Online payment",
    "YCKZF": "Advance deposit payment",
    "DaiTiJiao": "To be submitted",
    "KuaiDi": "Express delivery",
    "SongHuoShangMen": "Delivery",
    "ShangMenZiQu": "Home Pickup",
    "WuLiuHuoYun": "Logistics freight",
    "QiTa": "Other",


    //check-util

    "QSRBXY6WDM": "Please enter a password with at least 6 digits",
    "QSRBDY20WDM": "Please enter a password no larger than 20 digits",
    "MMGSCW": "Password format error",


    //common-utils

    "SJCW_QLXKF": "Data is wrong, please contact customer service",
    "ZBZCQD": "No sign-in support yet",
    "HuJiao": "call",
    "DLZHBCZ": "Login account does not exist",
    "QLXGLYKT": "Please contact the administrator to activate",
    "GQYZHBJY": "The corporate account is disabled",
    "QLXYSDH": "Please contact Yunshang for order",
    "GQYZHSYQJS": "The trial period of the enterprise account ends",
    "GQYZHYGQL": "The enterprise account has expired",
    "XYXFCNSY": "Renewal required to use",
    "GQYBCZCZH": "The company does not have this account",
    "NDDHZHYBQY": "Your order account has been disabled by Enterprise 1, and you cannot log in. Please contact the administrator",
    "NYBSQY1DHZ": "You are no longer a cooperative customer of Enterprise 1 and cannot log in. Please contact the administrator",
    "NDDPYBTY_B": "Your store has been deactivated and you cannot log in. Please contact the administrator",
    "GZHWKTDSZB": "The account has not opened the special feature of lighting. Please contact the administrator",
    "WXSQYZYGQ": "WeChat authorization verification has expired",
    "WXSQSB": "WeChat authorization failed",
    "WXSQSB_CWX": "WeChat authorization failed, error message",
    "DengLuGuoQi": "Login expired",
    "DLGQ_QCXDL": "Login expired, please log in again",
    "DLXXYJGQ_Q": "The login information has expired, please log in again",


    //data-constant

    "ShangJia": "Put on shelf",
    "YiXiaJia": "Removed",
    "YiJinYong": "disabled",
    "YiQiYong": "activated",
    "YiTingYong": "terminated",
    "KeHuZhuCe": "Customer registration",
    "XinZengDaoRu": "New import",
    "DDDSH": "Pending order review",
    "DCWSH": "Pending financial review",
    "DaiChuKu": "To be out of the library",
    "DCK_BF_": "Out of stock (partial)",
    "DFH_BF_": "To be shipped (partial)",
    "DSH_BF_": "To be received (partial)",
    "DSK_BF_": "To be collected (partial)",
    "WTHSQ": "No return application",
    "THDSH": "Returns are pending review",
    "THCLZ": "Return processing",
    "THYWC": "Return has been completed",
    "DYDHD": "Print order form",
    "DYFHD": "Print invoice",
    "DCDHD": "Export order form",
    "DCFHDWE_P": "Export invoice as Excel/PDF file",
    "TuiDanZuoFei": "Charge back",
    "DTHSH": "Pending return audit",
    "DaiRuKu": "Pending storage",
    "DRK_BF_": "Pending storage (partial)",
    "DFK_BF_": "Pending payment (partial)",
    "ChuangJianTuiDan": "Create chargeback",
    "BianJiTuiDan": "Edit chargeback",
    "TiJiaoTuiDan": "Submit a refund",
    "TuiHuoShenHe": "Return audit",
    "RuKu": "Warehousing",
    "RuKuShenHe": "Warehousing audit",
    "RuKuZuoFei": "Put into storage",
    "TianJiaFuKuan": "Add payment",
    "FuKuanZuoFei": "Payment is invalid",
    "FuKuanShenHe": "Payment review",
    "TuiDanWanCheng": "Refund completed",
    "QiTaChuKu": "Other outbound",
    "XiaoShouChuKu": "Sales out of stock",
    "PanKuiChuKu": "Out of stock",
    "DiaoBoChuKu": "Transfer out of library",
    "CaiGouTuiHuo": "Purchase returns",
    "TuiHuoRuKu": "Returned goods",
    "CaiGouRuKu": "Purchase storage",
    "QiTaRuKu": "Other storage",
    "PanYingRuKu": "Surplus inventory",
    "DiaoBoRuKu": "Transfer to storage",
    "CJCGD": "Create Purchase Order",
    "BJCGD": "Edit purchase order",
    "TJCGD": "Submit Purchase Order",
    "CGDZF": "Purchase order is invalid",
    "CGDWC": "Purchase order completed",
    "CGTHZF": "Purchase returns are invalid",
    "CGTHWC": "Purchase returns completed",
    "THDDY": "Return order printing",
    "THDDC": "Export order export",
    "CKDDY": "Print out receipt",
    "CKDDC": "Export order export",
    "GLDZH": "Management account",
    "DHDZH": "Order account",
    "ShenHeTongGuo": "examination passed",
    "SHBTG": "Audit not passed",
    "WuXuFaPiao": "No invoice required",
    "DaiKeXiaDan": "Valet orders",
    "ZiZhuXiaDan": "Place orders autonomously",
    "PCShangCheng": "PC Mall",
    "APPShangCheng": "APP Mall",
    "TVShangCheng": "TV Mall",
    "WeiXinShangCheng": "WeChat Mall",
    "ShouJiShangCheng": "Mobile phone mall",
    "ADDXSMLTC": "Gross profit margin on order",
    "ADDXSSRTC": "Commission on sales revenue",
    "AKHSSYG": "By customer's employee",
    "AnZhiDanRen": "Single player",
    "ATHJEBLKCT": "Deduct the commission amount in proportion to the amount returned",
    "DDYTHBJXTC": "Orders are returned without commission",
    "DaiDaKuan": "Pending payment",
    "WeiKaiShi": "has not started",
    "JinXingZhong": "processing",
    "YiGuoQi": "expired",


    //Utils.js

    "YouHuo": "In stock",
    "WuHuo": "Sold Out",
    "Yi": "Billion",
    "QianWan": "Ten million",
    "BaiWan": "million",
    "Wan": "Ten thousand",
    "Sheng1Tian": "1 day left",
    "Sheng1XiaoShi": "1 hour left",
    "1Miao": "1 second",
    "GangGang": "just",
    "1FenZhongQian": "1 minute ago",
    "1XiaoShiQian": "1 hour ago",
    "1TianQian": "1 day ago",
    "1GeYueQian": "1 month ago",
    "1NianQian": "1 year ago",
    "WeiZhiLeiXing": "Unknown type",


    //WechatUtil

    "YYGBCDSP_K": "There is a good product, take a look",
    "ZanWeiSheZhi": "Not set yet",


    //app

    "HQWXSQXX": "Get WeChat authorization information",


    //flexible

    "JGJYYDMBQ": "The zoom ratio will be set according to the existing meta tags",


    //index

    "NLJZZ": "Hard to load",


    //routes

    "ShangPinMingXi": "Product details",
    "ShangPinLeiBie": "Product category",
    "QXZFPNR": "Please select the invoice content",
    "QXZFPLX": "Please select invoice type",
    "QTXFPTT": "Please fill in the invoice",
    "FaPiaoTaiTou": "Invoice",
    "NSRSBH": "Taxpayer Identification Number",
    "FaPiaoNeiRong": "invoice content",
    "FaPiaoXinXi": "Invoice Information",
    "KaiJuFaPiao": "Invoice",
    "FaPiaoLeiXing": "invoice type",
    "Han": "Contain",
    "WeiHan": "Not included",
    "Shui": "tax",
    "QSRFPTT": "Please enter the invoice header",
    "QSRNSRSBH": "Please enter the taxpayer identification number",


    //routes

    //cart-bill

    "YiManZu_Man": "Satisfied [full",
    "ZaiGou": "Repurchase",
    "YMZ_DHJJZ": "Has been satisfied [Order price dropped",
    "JianJiangZhi": "Drop to",
    "YMZ_YDHJD": "Has satisfied [the original order price hit",
    "JianMeiJian": "Pieces per piece",
    "SYTCSLW0_WFJRGWC": "The number of remaining packages is 0 and cannot be added to the shopping cart",
    "QingXuanXeShangPin": "Please select a product!",
    "GSPYXJ": "This product is no longer available!",
    "ZJ2TN": "In the last 2 days",
    "QDQCXJSP": "Are you sure you want to clear the product?",
    "QingChuChengGong": "Cleared successfully",
    "TCSPBKJRGWC_QQXTCSPZYRGW": "Package products cannot be added to the shopping cart, please cancel the package products and then move to the shopping cart!",
    "SPSLBN": "The number of products cannot",
    "TaoCanHan": "Package includes",
    "TaoCan": "Package",
    "QHDSL": "Please check the quantity",
    "SPSLBNDY": "The number of products cannot be lower than",
    "XianDingLiang": "Limited quantity",
    "YiRuShouCang": "Move to favorites",
    "QuanXuan": "Select all",
    "JianZeng": "Gift",
    "JianLiXiang_Man": "Piece Li Xiang[full",
    "Yuan_": "US dollar]",
    "Zhe_": "fold]",
    "KCBZ_WFJRGWC": "Insufficient inventory to add to cart",
    "XDJEDYDY": "Order amount is greater than or equal to",
    "WYXJHYSXDSP": "No discontinued or expired products!",
    "DYQDL": "Below the MOQ",
    "ZSPG1J": "{count} products, total {number}",
    "WanCheng": "Carry out",
    "BianJi": "Edit",
    "You": "Have",
    "ZSPBFHYQ_W": "Kinds of products do not meet the requirements and are not checked, click to check the default address",
    "SPSLBNGY": "The number of products cannot be higher than",
    "JXDJ_CKXYGBFHYQSP": "Continue to click to see the next product that does not meet the requirements",
    "XiaDan": "Place order",
    "JianJiang": "Piece drop",
    "QRYCSP": "Confirm removal?",
    "QXZXDSP": "Please select the order product",
    "KCBZ_QCXXZ": "Insufficient stock, please choose again!",
    "KuCun": "In stock",
    "YCKXD": "Only 100 dollars can be placed",
    "QDSCSP": "Are you sure to collect items?",
    "ShouCangChengGong": "Collection success",
    "GYXDL": "Above the limit",
    "LiSheng": "Province",
    "GWCHSKD_QGYGB": "The shopping cart is still empty, go shopping",
    "QuGuangGuang": "Go shopping",
    "QCXJSP": "Clear products",
    "ZongE": "lump sum",
    "YouHui": "Discount",


    //cart-list

    "QXZJHRQ": "Please select a delivery date",
    "FuJiaFeiYong": "additional fees",
    "ZhongShangPin_Gong": "product, total ",
    "PeiSongShiJian": "Delivery Time",
    "QXZPSSJ": "Please select delivery time",
    "DingDanJinE": "Order amount",
    "SCFJ_ZD4Z": "Upload attachments, up to {num}, no more than {size}",
    "KeYongShangPin": "Available products",
    "ShiYongShuoMing": "Instructions for use",
    "YouXiaoQi": "Expiration date",
    "Man": "full",
    "BZZBZCTSFH": "Remarks temporarily do not support special symbols",
    "QingXuanZe": "please choose",
    "KaiPiaoXuJia": "Invoice needs to be added",
    "PeiSongFangShi": "Delivery Method",
    "ShangPinZongJia": "Commodity price",
    "YouHuiQuan": "Coupon",
    "10ZhangKeXan": "10 optional",
    "DDJEWMZ": "Order amount not met",
    "WDYHQ": "my discount coupon",
    "KYYHQ": "Available coupons",
    "100YuanKeYong": "$100 available",
    "ChaKanDingDan": "To view the order",
    "ShangPinHanShui": "Goods tax included",
    "SPWHS": "The goods do not include tax",
    "10GeDian": "10 points",
    "QueRenDingDan": "Confirm Order",
    "XZSHDZ": "New shipping address",
    "ShangPinQingDan": "Commodity list",
    "WKYYHQ": "No coupons available",
    "HuChi": "Mutually exclusive",
    "DaiJinQuan": "Voucher",
    "YunFei": "Freight",
    "ShuiFei": "Taxes",
    "WKYYY": "Reason for unavailability",
    "YHQYGQ": "Coupon has expired",
    "WDYHQSYSJ": "Coupon time",
    "ZDSPJEWMZ": "The specified product amount is not met",
    "BKYYHQ": "Unavailable coupons",


    //cart-presale-order

    "QXZPSFS": "Please select delivery method",
    "1ZSP_G": "1 product, total",
    "YSSP_YJ": "Pre-sale goods, expected",
    "JiaoHuoRiQi": "Delivery dates",
    "GMJLY": "Message to seller",


    //scan-to-cart

    "ZhengZaiChuLi...": "Loading...",
    "FSCSP": "Non-mall merchandise",
    "ZZCLTXM": "Loading...",
    "SPTJCG": "Product added successfully",
    "QSTXM": "Please scan the barcode",
    "SFSCDQSP": "Do you want to delete the current product?",
    "BQ_ZBDXGSP": "Sorry, no related products",
    "XuanZeDanWei": "Choose unit",
    "JRGWC": "Add to cart",
    "QXXZDJRSP": "Please select the product to be added first",
    "ZhengZaiChuLi": "Processing",
    "JRGWCCG": "Add to Cart successful",
    "ZanWuShangPin": "No products",


    //earn-brokerage-share

    "XTYJBNFZSP": "The system has copied the product link for you!",
    "NKYHDDYDYJ": "You can get the corresponding commission. Commissions are available in \"My\"",
    "LiChaKan": "View here",
    "WeiXinHaoYou": "WeChat friends",
    "ErWeiMa": "QR code",
    "CASBEWM": "Long press to identify QR code",
    "FZSB_QCXFZ": "Copy failed, please copy again!",
    "TWEWM": "Graphic QR Code",
    "BCDXC": "save into the album",
    "CATPBCDXC": "Long press the picture to save to album",
    "FuZhiLianJie": "copy Link",
    "FXZYJ": "Share earning commission",
    "YJZGKZQYJ": "Expected to earn the highest commission",


    //goods-share

    "FXSPGLSKH": "Share products to retail customers",
    "FXHJSCNZSD": "After sharing, your exclusive mobile micro-marketplace will be generated. You can set the price of the products in the marketplace, fill in your contact information, and attract consumers to come and buy.",
    "WSCDQJG_ND": "The current price of the micro mall: your order price ×",
    "DaiSheZhi": "To be set",
    "PengYouQuan": "Circle of friends",
    "GGFXSZ": "Change sharing settings",


    //wechat-login

    "QXSRZH": "Please enter the account number first",
    "MMGSCW_6_20W": "Password format error (6-20 letters or numbers)",
    "BDYYZH": "Bind an existing account",
    "WXKJDL": "WeChat quick login",
    "BangDingZhangHao": "Bind account",
    "HuanYingNi": "Welcome",
    "ZH_SJHM": "Account/Mobile Number",
    "MiMa": "password",


    //add-to-cart

    "KCBZ_BNTJG": "Insufficient inventory, can't add more",


    //combined-pay-online

    "ZhiFuChengGong": "payment successful!",
    "ZFSB_QCXCS": "Payment failed, please try again!",


    //

    "QXXZGG": "Please select the specifications first",
    "SXGGX": "Selected specifications required",
    "GMSLXYQDL_": "The purchase quantity is less than the MOQ, please buy again!",
    "GMSLDYXDL_": "The purchase quantity is greater than the limit order quantity, please re-purchase!",
    "ShangPinBianHao": "Product Number",
    "QiDing": "Minimum order",
    "SXGGDGSLBK": "The order quantity of the selected specifications cannot exceed",
    "KCBZ_BNTJ": "Insufficient inventory can not be added",
    "QXXZSP": "Please select the product first",
    "SLBNW0": "The quantity cannot be 0",
    "QXZGG": "Please select specifications",
    "LiJiYuDing": "order now",


    //nav

    "ShouYe": "Home",
    "ShangPin": "Commodity",
    "DingDan": "Order",
    "WoDe": "Mine",


    //pay-online

    "ZhiFuQuXiao": "Payment cancellation",


    //account-log

    "ShangPinYongJin": "Commodity commission",
    "YaoQingJiangLi": "Invitation reward",
    "TiXian": "withdraw",
    "TiXianBoHui": "Withdraw",
    "ZhuanWeiYuE": "Transfer to balance",
    "Jin7Tian": "Nearly 7 days",
    "Jin30Tian": "Nearly 30 days",
    "ShouZhiMingXi": "Income and Expenditure Details",
    "ZanWuShuJu": "No data",


    //client-statistics

    "WoDeKeHu": "My client",
    "WoDeYaoQing": "My invitation",


    //distribution-home

    "LeiJiKeHu": "Cumulative customers",
    "LeiJiDingDan": "Cumulative order",
    "YaoQingHaoYou": "invite friends",
    "LeiJiYaoQing": "Cumulative invitation",
    "LJSY_Y_": "Cumulative income (USD)",
    "BHDJS": "Include pending settlement",
    "J7TSY_Y_": "Profit in the last 7 days (USD)",
    "J7TXZKH_R_": "New customers (people) in the last 7 days",
    "KTXYJ": "Withdrawable commission",
    "XYJYO": "Need to cheer",
    "ChangJianWenTi": "common problem",
    "Q_ZMYJX_TG": "Q: How to carry out \"promotion to make money\"",
    "A_FXDPND_Z_": "A: Share the page link with \" Earn\" icon in the store to attract friends to click, friend registration (customer relationship is set to register and bind) or order (customer relationship is set to order binding) to buy in the store Products with the \" Earn\" icon will be counted as your \"promotion to make money\" income according to a certain calculation method.",
    "Q_RHJS_TGZ": "Q: How to calculate the income from \"promotion of making money\"?",
    "A_MGD_Z_TBD": "A: Every product with \" Earnings\" icon has a certain percentage of commission, without this mark means that the product does not participate in \"promotion to make money\". After a friend makes a successful payment through your sharing link, the commission will be calculated separately according to the commission percentage of each product in the order (the commission that does not participate in the promotion becomes 0), and finally the commission income of the order will be accumulated. The amount of freight, refund, full reduction, etc. will be deducted from the revenue calculation, and the final transaction price shall prevail.",
    "Q_TGZQ_DSY": "Q: When will the income from \"promotion make money\" arrive?",
    "A_HYTGNDFX": "A: After your friend has successfully paid through your sharing link, you can see this promotion in the promotion order in the sales center. The status is to be settled. After the customer confirms the receipt of the order, the order transaction is settled, and this revenue will be automatically transferred to Your personal account can be withdrawn at any time.",
    "Q_LJKH_LJY": "Q: Does the cumulative customer and cumulative invitation list have no avatar nickname?",
    "A_WXYQZYGZ": "A: WeChat requires that you only get the nickname of the avatar if you pay attention to the authentication service number. The customer with the authentication service number pays attention to the public account of the store, and the non-certification service number pays attention to the public account of \"ordering on the cloud\". .",
    "Q_WSMWASSG": "Q: Why didn't I get the promotion revenue when sharing according to the above rules?",
    "A_1_HYKNBW": "A: 1. The friend may not have established a customer relationship with you (the friend clicked the link of another salesperson to register or place an order) 2. If the friend opens your sharing link outside the WeChat environment (such as the default browser of the mobile phone), If the browser does not open cookies, it will not be able to record customer relationships, that is, the promotion order cannot be recorded. 3. If the above is not a problem, the promotion order is still not recorded, please contact the staff",


    //

    "XiaoShouZhongXin": "Selling Centre",


    //generalize-goods

    "SPMC_GG_BH": "Product name / specifications / number",
    "YuJiZhuan": "Expected to earn",


    //invitation-home

    "YaoQingKa": "invitation card",
    "YNYQJR_TGY": "You are invited to join together to promote and win rewards",
    "DJBCDXC": "Click Save to Album",
    "JYQKFSGHY_": "Send the invitation card to friends to promote the store rewards",


    //join-home

    "HuanYingJiaRu": "Welcome",
    "YQNCW": "Invite you to become",
    "LiJiJiaRu": "Join now",


    //order-statistics

    "YuJiYongJin": "Estimated commission",
    "YongJin": "commission",
    "YuJiJiangLi": "Estimated reward",
    "JiangLi": "reward",
    "SPTGDD": "Product promotion orders",
    "YQJLDD": "Inviting reward orders",


    //performance-statistics

    "YeJiTongJi": "Performance statistics",
    "LJKH_R_": "Cumulative customers (person)",
    "SPYJ_Y_": "Commodity commission (USD)",
    "HanDaiJieSuan": "Including pending settlement",
    "XGMCJS": "Explanation of related terms",
    "KeHu": "client",
    "HFXYBDLKHG": "Buyers or distributors who bind customer relationships with distributors",
    "YaoQing": "invite",
    "FXYCGYQDXJ": "Sub-distributors successfully invited by distributors",
    "FXYTJKHGMH": "After the distributor recommends the customer to buy, the distributor can get the commission",
    "XJFXYTGSPH": "After the lower level distributor promotes the product, the higher level gets the reward",


    //posters-promoting

    "NiCheng": "nickname",
    "HaiBaoTuiGuang": "Poster promotion",
    "JHBFSGHY_T": "Sending posters to friends and promoting the store pays off",


    //settlement-home

    "JieSuanZhongXin": "Settlement Center",
    "KTXJE": "Withdrawable cash",
    "TiXianJiLu": "Withdrawals record",


    //withdraw-account

    "KHRXMBNWK": "Account holder name cannot be empty!",
    "KHHBNWK": "Account bank cannot be empty!",
    "YHKHBNWK": "The bank card number cannot be empty!",
    "SFZTPBNWK": "ID card picture cannot be empty!",
    "YHKTPBNWK": "Bank card pictures cannot be empty!",
    "BaoCunChengGong": "Saved successfully!",
    "SCSFZ": "Upload ID",
    "SCYHK": "Upload bank card",
    "BaoCun": "save",
    "TiXianZhangHu": "Withdraw account",
    "TS_QTXZSXX": "Tip: Please fill in the real information, and only then withdraw it after passing the review",
    "KHRXM": "Account holder name",
    "KaiHuHang": "Account Bank",
    "YinhangKaHao": "Bank card number",
    "QSRZSXM": "please enter your real name",
    "QSRKHHMC": "Please enter the bank name",
    "QSRYHKH": "Please enter the bank card number",


    //withdraw-deposit

    "QXSZTXZH": "Please set up a withdrawal account first",
    "QuSheZhi": "To set",
    "JEXY10Y_BKT": "The amount is less than 10 US dollars and cannot be withdrawn!",
    "JECGKTXJE": "The amount exceeds the cash withdrawal amount!",
    "CGDRKTXSX_": "Exceed the upper limit for withdrawals on that day, and the upper limit for single withdrawals is $20,000!",
    "QueDingJiang": "Sure will",
    "ZhuanDaoYuE": "Go to balance?",
    "1_JEDY10YSB": "1. No withdrawal when the amount is less than 10 US dollars;",
    "2_BCTXDZSJ": "2. The withdrawal time for this withdrawal is within 5 working days after the successful withdrawal application is initiated, and the single-day withdrawal limit is USD 20,000;",
    "3_GBSRWHSS": "3. The income is tax-included income, please go to the relevant tax authorities to declare and pay personal income tax!",
    "4_ZDYEKYYS": "4. The transfer balance can be used for shopping in the mall;",
    "TXSQCG_QDD": "Withdrawal application successful, please wait for review",
    "JEDYKTXJE_": "The amount is greater than the cash withdrawal amount and cannot be transferred to the balance!",
    "ZZYECG_QDG": "The transfer balance is successful, please check it in the personal center!",
    "BenCiTiXian": "This withdrawal",
    "ZhuangDaoYuE": "Go to balance",
    "TiXianDao": "Withdraw to",
    "JEW0BKZZYE": "The amount of 0 cannot be transferred to the balance!",
    "DRTXSX2WY": "Daily withdrawal limit of USD 20,000",
    "QuanBuTiXian": "Withdraw all",
    "QueRenTiXian": "Confirm withdrawal",


    //goods-filter-tab

    "ZuiChangGouMai": "Most frequently purchased",
    "MoRenPaiXu": "Default sort",
    "ZongHe": "Comprehensive",
    "FenLei": "classification",
    "XiaoLiangYouXian": "Sales priority",
    "XiaoLiang": "Sales",
    "JiaGe": "Price",
    "ShaiXuan": "filter",
    "ZuiJinGouMai": "Recently purchased",
    "CuXiaoYouXian": "Promotion priority",
    "QuanBuFenLei": "All Categories",
    "ZhiKanCuXiao": "Only look at promotions",
    "XuanZeFenLei": "Choose category",
    "QXZFL": "please select a type",


    //goods-list-default

    "ZWXGSP": "No related products",


    //goods-brand-list

    "PinPaiHui": "Brand Exchange",


    //goods-classify-filter

    "TuiJianFenLei": "Recommended categories",
    "ShangPinFenLei": "Categories",
    "GengDuo": "More",


    //goods-detail

    "DLZHBSDQQY": "The login account is not the current corporate customer account",
    "GXNHQ": "Congratulations on your acquisition",
    "SZWSCJGH_C": "Only after setting the price of the micro mall",
    "JHDJSHS_": "Until the end of the event",
    "DLHKFX": "Share after login",
    "ZZHQXX_QSH": "Getting information, please try again later",
    "Zhang": "Zhang",
    "Tian": "day",
    "QXZGGZQPD": "Please select the specifications and then go with the lights!",
    "Yuan": "US dollar",
    "XS_F_M": "Hour/minute/second",
    "CX": "Promotion,",


    //integral

    "WoDeJiFen": "My scores",
    "DBL40_DHY": "Defeat 40% of members",
    "JiFen": "integral",
    "ZhuanJiFen": "Earn points",
    "JiFenMingXi": "Points Details",
    "HuanLiPin": "Change gifts",
    "CKGDHL": "See more gifts",
    "ChaDingDan": "Check order",


    //integral-convert

    "DHSLBNCG": "Redemption quantity cannot exceed",
    "DuiHuanShuLiang": "Exchange quantity",
    "SuoXuJiFen": "Points required",


    //integral-detail

    "LiPinXiangQing": "Gift details",
    "WoYaoDuiHuan": "I want to exchange",
    "ShengYu": "Remaining",
    "TuWenXiangQing": "Graphic details",
    "YiDuiWan": "Finished",


    //integral-filter

    "FenLeiShaiXuan": "Category screening",
    "JiFenShaiXuan": "Points screening",


    //integral-flow

    "JiFenShouRu": "Point income",
    "JiFenZhiChu": "Point spend",


    //integral-logistics

    "DingDanGenZong": "order tracking",
    "WuLiuGongSi": "Logistics company",
    "WuLiuDanHao": "shipment number",


    //integral-order

    "DingDanLieBiao": "Order List",
    "YiFaHuo": "Shipped",
    "JiFenDingDan": "Point order",
    "ChaKanWuLiu": "View Logistics",


    //integral-order-create

    "LPSLBZ_QCX": "There are not enough gifts, please repay",
    "JiFenBuZu": "Insufficient points",
    "ZhiFuXinXi": "Payment Information",
    "JiXuGuangGuang": "Continue shopping",
    "QTXSHXX": "Please fill in the receipt information",
    "DuiHuanChengGong": "Redemption succeeded",
    "LPDHQR": "Gift redemption confirmation",
    "SCCKJ": "Market reference price",
    "LiJiZhiFu": "pay immediately",


    //integral-order-detail

    "DingDanXiangQing": "Order details",
    "DingDanZhuangTai": "Order Status",
    "DingDanBianHao": "Order number",
    "DuiHuanShiJian": "Redemption time",
    "ShouHuoXinXi": "Delivery information",
    "HuaFeiJiFen": "Spend points",


    //integral-rule

    "QDZJF": "Sign in to earn points",
    "XDZJF": "Place points to earn points",
    "DDWCHKHDYD": "After the order is completed, you can get the order amount*",
    "1_MRQD_JL": "1. Daily check-in and rewards",
    "2_LXQD3T_EW": "2. Sign in for 3 consecutive days, additional rewards",
    "3_LXQD7T_EW": "3. 7 consecutive days of registration, additional rewards",
    "BeiDeJiFen": "Points",
    "DLZJF": "Log in to earn points",
    "MeiRiDengLu": "Log in daily",
    "SC_JL": "Mall, reward",


    //integral-rule-detail

    "GuiZe": "rule",


    //integral-sign

    "QianDaoYouLi": "Sign in courteously",
    "QianDaoGuiZe": "Sign-in rules",
    "YLXQD": "Checked in consecutively",
    "QDCG_NYJLX": "Check-in succeeded, you have checked in continuously",
    "QDCG_BCHD": "Sign in successfully, get this time",


    //

    "QSRBZ": "Please enter a remark",
    "DingDanZengPin": "Order giveaway",
    "GuiGeBianHao": "Specification number",
    "GuiGe": "Specification",


    //combined-pay

    "CDDWXFK": "No payment for this order",
    "YHYEBZ": "Insufficient user balance!",
    "ZFJEBNW0": "Payment amount cannot be 0!",
    "ZFJEBNDYDD": "The payment amount cannot be greater than the order pending payment amount",
    "DFKJE": "Amount to be paid",
    "FuKuanFangShi": "payment method",
    "FuJian": "annex",
    "ZHYFZ": "Account copied",
    "GHSKZH": "Change payment account",
    "SYXXZFQSCF": "To use offline payment, please upload the attachment of the payment screenshot so that the finance can confirm the payment!",
    "XXZF_BXXZS": "To pay offline, you must select the receiving account",
    "DingDanZhiFu": "s",
    "ZFFS_HDFK": "Payment method: cash on delivery",
    "BCFKJE": "The amount of this payment",
    "YuE": "Balance",
    "FuZhiZhangHao": "Copy account",
    "WSZXCXZFPZ": "Applet payment configuration is not set",
    "XCXZFZHJY": "Applet payment account disabled",
    "SRZFJE": "Enter payment amount",
    "FuKuanZhangHu": "payment account",
    "QueRenZhiFu": "confirm payment",
    "ShaoHouZhiFu": "Pay later",


    //order

    "FKJLYTJ_DD": "Payment record has been added, pending financial review",
    "JiXuGuang": "Continue shopping",
    "BenCiFuKuan": "This payment",
    "FuKuanChengGong": "Successful payment",
    "ZhiFuFangShi": "payment method",
    "ZhiFuDingDan": "Payment order",


    //order-batch-pay

    "QXXZSXZFDD": "Please select the order to be paid first",
    "PiLiangFuKuan": "Bulk payment",
    "FuKuan": "Payment",
    "QuFuKuan": "Go to pay",


    //order-create-result

    "YingFuJinE": "Amounts payable",
    "XiaDanShiJian": "order time",


    //order-deliver

    "SFQRSH": "Do you confirm receipt?",
    "DDQRSH_": "Order confirmation receipt?",
    "DFHSP": "Products to be shipped",
    "ShangPinShuLiang": "amount of goods",
    "ChuKuShiJian": "Delivery time",
    "FaHuoShiJian": "Delivery time",
    "WuLiuBeiZhu": "Logistics notes",
    "QueRenShouHuo": "Confirm receipt",


    //

    "RYFK_QJSYG": "If payment has been made, please contact the administrator in time",
    "QSRQXYY": "Please enter the reason for cancellation",
    "H_DDJZDGB_QJKF": "After the order will be automatically closed, please pay as soon as possible",
    "TuiHuoQingKuang": "Returns",
    "DingDanZongE": "Order total",
    "DingDanBeiZhu": "Order notes",
    "FaHuoQingKuang": "Delivery situation",
    "DingDanFuJian": "Order attachment",
    "ShenQingTuiHuo": "Application for return",
    "G1TDSHJL_QRS": "A total of {num} record of goods to be received, confirm the receipt?",
    "DaiXiaDan": "Place an order",
    "DingDanHao": "Order number",
    "XiaDanFangShi": "Order method",
    "TuiHuoDan": "Return form",
    "ShangPinZongE": "Total merchandise",
    "YunFei_ZongZhong": "Freight (total weight",
    "SF_SPWS": "Taxes and fees (goods not taxed",
    "TePiJia": "Special price",
    "FuKuanQingKuang": "Payment situstion",
    "TiaoJiLu": "Records",
    "ZaiLaiYiDan": "One more order",
    "QDSCGDD_": "Are you sure you want to delete this order?",
    "YiQueRen": "confirmed",
    "YiShouHuo": "received",
    "DaiFuJinE": "Amount to be paid",
    "YiFuJinE": "Amount paid",
    "ZanWuJiLu": "No records",


    //

    "SPMC_DDH": "Product name / order number",
    "FuKuanZhuangTai": "Payment status",
    "FaHuoZhuangTai": "Delivery status",
    "QiShiShiJian": "Start time",
    "ZhongZhiShiJian": "stop the time",


    //order-list

    "CaoZuoChengGong": "Successful operation",
    "ZBKGM": "Not available for purchase",
    "DQDDSPYXJ": "The current order item is no longer available",
    "DQDDBFSPYXJ": "Some products in the current order have been removed",
    "JTJSJSP": "Only add products",
    "DingHuoDan": "Order form",


    //order-log

    "DingDanRiZhi": "Order log",


    //order-logistics

    "FuZhiChengGong": "Copy successful",
    "FuZhiShiBai": "Copy failed",
    "FuZhi": "copy",
    "BoDa": "Call",
    "WCWLDHXX": "No such logistics order information",


    //order-pay

    "ZFCS_QCXXD": "Payment is overtime, please re-order",
    "YQYYCKZF": "Pre-deposit payment is enabled",
    "BNDYDFKJE": "Cannot be greater than the amount to be paid",
    "ZFJEBNWK": "Payment amount cannot be empty",
    "QXAPFK_FKH": "Please arrange the payment first, upload the payment information on the screenshot after payment, so that the financial confirmation of the payment",
    "DDTJCG_QJS": "Order submitted successfully, please pay in time",
    "QXZFKFS": "Please choose a payment method",
    "SYYEZFH_HX": "After paying with balance, you have to pay",


    //

    "FuKuanJiLu": "payment record",
    "ZWFKJL": "No payment history",
    "FuKuanXiangQing": "Payment details",
    "FuKuanJinE": "Payment amount",
    "ZhiDan": "Make order",
    "ShenHe": "Audit",
    "ZuoFei": "Void",
    "ZuoFeiYuanYin": "Reason for invalidation",
    "GLDHD": "Related order",
    "HeXiaoJinE": "Write-off amount",
    "HeXiaoZhuangTai": "Write-off status",
    "DanJuZhuangTai": "Document status",
    "KeHuMingCheng": "client's name",
    "ZhangDanHao": "account number",
    "ShouKuanZhangHao": "Accounts receivable",
    "ShouKuanShiJian": "Collection time",
    "FuKuanPingZheng": "Proof of payment",
    "2GeFuJian": "2 attachments",


    //order-statistics

    "DingDanTongJi": "Order statistics",
    "DingHuo": "Place an order",
    "Bi": "pen",


    //order-statistics-detail
    "DDTJMX": "Order Statistics Details",
    "AGGTJ": "Statistics by specifications",
    "AFLTJ": "Statistics by category",
    "DDJE_Y": "Order amount/USD",
    "DHSP_Z": "Order product/species",
    "DingHuoDan_Bi": "Order Form/Pen",
    "DingHuoShangPin": "Order goods",
    "JinE": "Amount",


    //refund-list
    "TuiHuo": "Return",

    //refund-apply
    "THDHWBC_QD": "The return order has not been saved. Are you sure to leave?",
    "QSRSQTHJE": "Please enter the refund amount",
    "QSRTHJE": "Please enter the return amount",
    "QSRBZXX": "Please enter remark information",
    "YingTuiJinE": "Refundable amount",
    "TJTHSQ": "Submit a return request",
    "QXXZDHQD": "Please select the order list first",
    "DingHuoQingDan": "Order list",
    "SQTHJE": "Request return amount",
    "DJXZSP": "Click to select product",
    "TuiHuoJinE": "Returned amount",


    //refund-detail
    "THDDXQ": "Return order details",
    "YiTuiHuo": "Returned",
    "YiTuiKuan": "refunded",
    "THDZT": "Return order status",
    "TuiHuoDanHao": "Return order number",
    "TuiHuoShiJian": "Return time",
    "TuiHuoCangKu": "Return warehouse",
    "THLXR": "Return Contact",
    "TuiHuoQingDan": "Return list",
    "TuiKuanZongE": "Total refund",
    "TuiKuanQingKuang": "Refund situation",
    "DaiTuiJinE": "Amount to be refunded",
    "YiTuiJinE": "Refunded amount",


    //refund-product

    "SPMC_BH_GG": "Product name / serial number / specification serial number / barcode",
    "GZD1TJG": "1 result found",
    "JiaRuQingDan": "Add to list",


    //refund-result

    "ShenQingChengGong": "Successful application",
    "THDTJCG": "Return form submitted successfully",
    "ChaKanXiangQing": "See details",


    //goods-package-floor

    "HDWKS_WDZL": "The event has not started, come back later",
    "HDYJS_XCZD": "The event is over, come early next time",
    "ZWSZBT": "No title set yet",
    "ChaKanGengDuo": "see more",


    //goods-promotion-floor

    "HCDZSJC": "Caching causes time difference",
    "DLCKCXJ": "Log in to view sale price",
    "ZWSZCX": "No promotion",
    "JuKaiShi": "From the start",
    "Song": "give away",
    "JuJieShu": "End",
    "YiJieShu": "over",


    //search-floor

    "XiaoXi": "news",
    "SaoMaXiaDan": "Scan the order",


    //home-page-custom

    "WZLCSJ": "Unknown floor data",


    //jump-error

    "QXZYSDHAPP": "Please download the \"Cloud Order\" APP",
    "QMTYQYGLD": "Fully experience the enterprise management side",
    "XZYSDHAPP": "Download the \"Cloud Order\" APP",
    "HQGLDWZTY": "Get a complete experience on the management side",
    "JXTYDHD": "Continue to experience the ordering end",


    //notice-detail

    "GongGaoXiangQing": "Announcement details",
    "GongGaoFuJian": "Announcement attachment",


    //shop-error

    "NFWDYMBCZO": "The page you visited does not exist~",
    "KeNengYuanYin": "possible reason",
    "1_ZDZLSRCWD": "1. Enter the wrong address in the address bar;",
    "2_DJLGQLJ": "2. Clicked the expired link.",


    //shop-index

    "GuanLiDuan": "Management side",
    "ZhuCe": "Registered",
    "HYNTYYSDH": "You are welcome to experience ordering on the cloud",
    "TYZHSJHDQG": "Experience account data will be updated regularly, please do not enter important information",
    "TYZHZDNRSJ": "The content data in the experience account are fictitious, please do not enter important information",
    "ZHZCCG": "Account registration successful",
    "DengLuZhangHao": "Login account",
    "CSMMW": "The initial password is",
    "GNFYHQL": "Send you a coupon",
    "Man1YuanKeYong": "Full {num} yuan available",
    "LQH1TNKY": "Available within {day} days after receipt",
    "YiJianLingQu": "One-click collection",
    "GXNHD1ZYHQ": "Congratulations on getting 1 coupon",
    "Gong1Yuan": "1 USD total",
    "LiJiShiYong": "use immediately",


    //shop-login

    "NHWDL": "You are not logged in",


    //shop-notice

    "ShangChengGongGao": "Mall announcement",
    "ZanWuGongGao": "No announcement",
    "1GeFuJian": "{num} attachment",


    //deposit-detail

    "KouKuan": "Deduction",
    "ChongZhi_Kuan": "Top up",
    "KouKuanZuoFei": "Deduction",
    "ChongZhiZuoFei": "Top up",
    "ZhangHuMingXi": "Account details",
    "ZhangHuYuE": "Account Balance",
    "LiJiChongZhi": "Top up now",


    //deposit-recharge

    "CZJEBNXY1Y": "The recharge amount cannot be less than 1 USD",
    "QSRZQDSZ": "Please enter the correct value",
    "ZDYJE": "Custom amount",
    "ZWKTXSCZGN": "Online recharge function has not been activated yet",
    "ShiFuJinE": "The amount actually paid",
    "ZhangHuChongZhi": "Account recharge",
    "QXZCZJE": "Please select the recharge amount",
    "QSRCZJE": "Please enter the recharge amount",
    "QLXNDZSYWY": "Please contact your dedicated salesperson to recharge",
    "ShouJia": "Selling price",
    "YiShouQing": "Sold out",
    "ChongZhiJinE": "Recharge amount",
    "QueRenFuKuan": "confirm payment",


    //deposit-recharge-result

    "QSDDID": "Missing order id",
    "ChongZhiChengGong": "Deposit successfully",
    "ChongZhiShiBai": "Recharge failed",
    "CLZ_QSH...": "Processing, please wait...",
    "ChongZhiFangShi": "Recharge method",
    "ChongZhiShiJian": "Recharge time",
    "JYLSH": "Transaction serial number",
    "FanHuiShangCheng": "Back to the mall",


    //recharge-online

    "ZFSB_QCS": "Payment failed, please try again",
    "ZFYQX": "Payment canceled",
    "ZFYC_QCS": "Payment is abnormal, please try again",


    //user

    "LingQuanZhongXin": "Coupon Center",
    "WeiShiYong": "Unused",
    "YiShiYong": "Used",
    "ZWYHQ": "No coupons",


    //keep-list

    "YiGouMai": "bought",
    "ChangYongQingDan": "Common list",
    "WoDeShouCang": "My collection",
    "QuGouWuChe": "Go shopping cart",
    "GeShangPin": "Products",


    //login

    "YunShangDingHuo": "Order on the cloud",
    "QSRZH": "please enter account!",
    "QSRMM": "Please enter the password!",
    "QSRDLZH": "Please enter a login account",
    "JiZhuMiMa": "Remember password",
    "WangJiMiMa": "forget password?",
    "DengLu": "log in",
    "DLZHBCZ_QL": "The login account does not exist, please contact the administrator to activate",
    "GQYZHSBJY_": "The account of the company is disabled, please contact \"Cloud Order\"",
    "GQYZHSYQJS_": "The trial period of the enterprise account is over, please contact \"Cloud Order\"",
    "GQYZHYGQL_": "The enterprise account has expired and needs to be renewed before it can be used. Please contact \"Cloud Order\"",
    "LiJiTiYan": "Try it now",


    //my-collect

    "YiChuChengGong": "Successfully removed",
    "QueHuo": "out of stock",
    "YCSCJ": "Remove from favorites",


    //order-message

    "SheZhiChengGong": "Set up successfully",
    "ZWWDXX": "No unread messages",
    "QuanBuYiDu": "All read",
    "DingDanXiaoXi": "Order message",


    //

    "YiQiang": "Robbed",
    "LiJiLingQu": "get it right now",
    "LingQuanHou": "After receiving the coupon",
    "7TianNeiKeYong": "Available within 7 days",


    //

    "QSRSJH": "Please enter phone number",
    "QSRYZM": "please enter verification code",
    "QSRXMM": "Please enter a new password",
    "QSRZQSJGS": "Please enter the correct mobile phone format",
    "ChongZhiMiMa": "reset Password",
    "QSRNDSJHM": "please enter your phone number",
    "QSRDXYZ": "Please enter SMS verification",
    "HQYZM": "get verification code",
    "HKCXHQ": "Can be retrieved later",
    "QSR6_20WSZHZ": "Please enter a password of 6-20 digits or letters",


    //share-setting

    "QSRSPJGBL": "Please enter the product price ratio",
    "SPJGBLBXDY": "Commodity price ratio must be greater than or equal to 100 and less than 999.99",
    "DPMCZBZCTS": "Store name does not support special symbols",
    "LXRZBZCTSF": "Contacts currently do not support special symbols",
    "LXFSZBZCTS": "Contact information does not support special symbols",
    "LXDZZBZCTS": "Contact address does not support special symbols yet",
    "DPJJZBZCTS": "Store introduction does not support special symbols",
    "QSRDPJJ_ZD": "Please enter the store description, up to 50 characters",
    "FenXiangSheZhi": "Sharing settings",
    "NDWSCSPJG": "Your micro mall price",
    "NDDHJ_": "Your order price×",
    "LXXX_KBJ_": "Contact information (editable)",
    "DianPuMingCheng": "Store Name",
    "JianJie": "Introduction",
    "QSRDPMC": "Please enter the store name",
    "LianXiRen": "Contact person",
    "QSRLXR": "Please enter a contact",
    "LianXiFangShi": "Contact information",
    "QSRLXFS": "Please enter contact information",
    "LianXiDiZhi": "contact address",
    "QSRLXDZ": "Please enter contact address",


    //user

    "WoDeDingDan": "My Order",
    "ZuiJinLiuLan": "Recently Viewed",
    "ShouCangShangPin": "Favorite Product",
    "BDYYZH_ZC": "Bind an existing account | Register a new account",
    "DL_ZC": "Login | Register",
    "BangDingShouJi": "Bind phone",
    "TS_BDSJHM_": "Tip: Bind the mobile phone number, the account is safer!",
    "ChaKanQuanBu": "view all",
    "ShouHuoDiZhi": "Shipping address",
    "LianXiWoMen": "contact us",
    "SheZhi": "Set up",
    "QuQianDao": "Sign in",
    "JiFenShangCheng": "Points Mall",
    "TuiGuangShangPin": "Promotion of goods",
    "QuChaKan": "Go check",
    "JiFenHuanLi": "Redeem points",
    "DYSPBQ": "Print product labels",


    //user-about

    "GYYSDH": "About \"Cloud Order\"",
    "YQHYSMEWM_": "Invite friends to scan the QR code, directly download and install",
    "DangQianBanBen": "current version",


    //user-address

    "ZWSHDZ": "No delivery address",
    "WoDeDiZhi": "my address",
    "ZhiNengShiBie": "Intelligent Recognition",
    "ZTDZXXKZNS": "Paste address information can intelligently identify name, phone, address",
    "MoRen": "default",

    //

    "QRSCDZ": "Confirm to delete address?",
    "SHRXM": "Consignee name",
    "SJHMHGDDHZ": "Fill in at least one mobile phone number or landline",
    "QSRSHRXM": "Please enter the consignee's name",
    "ShouJiHaoMa": "Mobile phone number",
    "GuDingDianHua": "Fixed telephone",
    "QSRGDDH": "Please enter a landline",
    "SuoZaiDiQu": "Area",
    "QSRSZDQ": "Please enter your region",
    "QSRXXDZ": "Please enter the detailed address!",
    "QSRZQDSJHM": "Please enter the correct phone number",
    "QSRZQDGDDH": "Please enter the correct landline",
    "XiangXiDiZhi": "Address",
    "MoRenDiZhi": "Default address",
    "SWMRDZ": "set as the default address",
    "BCBSY": "Save and use",


    //user-bing-one

    "QSRSJHM": "Please enter the phone number",
    "GYHYZC": "This user is already registered",
    "GSJHMYJZCG": "The mobile number has already been registered",
    "QZJDLJK": "Please log in directly",
    "QuDengLu": "Log in",
    "BDHKYTGSJH": "After binding, you can log in through your mobile phone number, which is convenient for you to retrieve your password and improve account security",
    "_BDHKYTGSJ": "*After binding, you can log in by mobile phone number, which is convenient for you to retrieve your password and improve account security",
    "QSRSJYZM": "Please enter the phone verification code",
    "BDXSJ": "Bind new phone",
    "DXYZM": "SMS verification code",
    "XiaYiBu": "Next step",
    "TGCBZ": "Skip this step",


    //user-bing-two

    "DBDSJHMBCZ": "The phone number to be bound does not exist!",
    "QSRDLMM": "Please enter your password!",
    "_GSJHMYBDY": "*The mobile phone number has been bound to the ordering account on the cloud, verify the mobile phone login password, you can bind the current account",
    "DQZHMMJTBW": "The current account password will be synchronized to the phone login password",
    "DangQianZhangHao": "Current account",


    //

    "QDYJZ10JSP": "Are you sure you want to delete these 10 products?",
    "ShanChuChengGong": "successfully deleted!",


    //user-info

    "SJGSCW": "Phone format error",
    "YXGSCW": "Mailbox format error",
    "WoDeZhangHao": "My account",
    "YouXiang": "mailbox",
    "ShouJi": "Cell phone",
    "XingMing": "Name",
    "XiuGaiTouXiang": "Modify avatar",
    "JiBenXinXi": "Basic Information",
    "ZhangHaoAnQuan": "Account security",
    "XiuGaiMiMa": "change Password",
    "DianJiBangDing": "Click to bind",
    "RYDSHTY_QL": "If it has been lost or disabled, please replace it immediately to avoid account theft",
    "WBZNDZHAQ_": "To protect the security of your account, please bind your phone",
    "QSRNDXM": "please enter your name",
    "QSRNDDH": "Please enter your phone",
    "QSRNDYX": "please input your email",


    //user-setting

    "QCHCHXCXDL": "Login again after clearing cache",
    "QingChuHuanCun": "clear cache",
    "QDYQCHCM": "Are you sure you want to clear the cache?",
    "TuiChuDengLu": "Sign out",
    "QieHuanZhangHao": "Switch account",


    //user-supplier

    "LianXi": "contact",
    "GongSiDianHua": "work phone",
    "DiZhi": "Address",


    //vouchers-detail

    "DJQMX": "Voucher details",


    //vouchers-list

    "BuKeYong": "unavailable",
    "KeYong": "Available",
    "ZWDJQ": "No vouchers",
    "ZongEDu": "Total amount",
    "FaFangShiJian": "Release time",
    "EDYYW": "Quota exhausted",
    "QBSPKY": "All products are available",


    //vouchers-select-list

    "WDDJQ": "My voucher",
    "KYDJQ": "Available vouchers",
    "DJQKDK": "Vouchers are deductible",
    "CKKYSP": "View available products",
    "BKYDJQ": "Unavailable vouchers",
    "BKYYY_ZWFH": "Reason for unavailability: No eligible products",

    //goods-detail

    "DQSPWSCGGT": "The current product has not uploaded the specifications, and it does not support lamp matching!",
    "BoFangShiPin": "Play video",
    "Gong1GeGuiGe": "A total of {num} specifications",
    "YiXuanGuiGe": "Selected specifications",
    "XuanGuiGe": "Choose specifications",
    "LingQuan": "Collect coupons",

    "1ZhongCuXiao": "1 promotion",
    "1ZhangKeYong": "1 available",
    "ZuiGaoKeSheng": "Maximum saving",
    "ShangPinTaoCan": "Commodity packages",
    "Gong1GeTaoCan": "1 package",
    "ShangPinCanShu": "Product parameters",
    "ZhanKai": "Unfold",
    "YiShouCang": "collected",
    "ShouCang": "Collect",
    "KeFu": "Customer service",
    "XiangQing": "Details",
    "TuiJian": "recommend",
    "BianHao": "Numbering",
    "ShangPinXinXi": "Product information",
    "KLYHQ": "Coupons available",
    "KuCunBuZu": "Inventory shortage",
    "DuoGuiGe": "Multiple specifications",
    "YiShou": "Sold",
    "SWBHZ_YS": "Sold out for replenishment, sold",
    "CuXiaoShangPin": "Promotional items",


    //goods-search

    "NHWSRSSNR": "You have not entered the search content",
    "SouSuo": "search for",
    "SSY1XGDSP": "Search for products related to {searchKey}",
    "Yue1GeShangPin": "About {num} products",
    "ZuiJinSouSuo": "Recently searched",
    "QingChu": "Clear",
    "ZWSSJL": "No search history",


    //goods-property

    "ZGJGBNDYZD": "The highest price cannot be lower than the lowest price",
    "ShangPinBiaoQian": "Commodity label",
    "BuGaoYu1": "Not higher than 1",
    "BuDiYu1": "Not less than 1",
    "FengGe": "style",


    //goods-promotion-list

    "JiJiangKaiShi": "About to start",
    "QuanBuCuXiao": "All promotions",


    //goods-package-list

    "Gong1JianShangPin": "1 products",
    "Sheng": "Save",


    //goods-package-detail

    "GTCZGWCZDS": "The quantity of the package in the shopping cart has reached the purchase limit, and it cannot be added to the shopping cart",
    "GTCKCBZ_BK": "The package has insufficient inventory and cannot be added to the shopping cart",
    "JinSheng1Tao": "Only 1 set left",
    "XianGou1Tao": "Limit 1 set",
    "TaoCanXiangQing": "Package details",
    "DanMaiJia": "Single purchase price",
    "TaoCanJia": "Package price",
    "HDYJS": "The event is over",


    //goods-message

    "WDL_QXDL": "Not logged in, please log in first",


    //goods-list-label

    "XinPinShangShi": "New product launch",
    "WDLZH_ZWCX": "No account, no promotions!",
    "YiXiaShangPin": "The following products",


    //goods-list
    "1ZhongGuiGe": "1 specification",
    "YouKuCun": "In stock",


    //goods-filter
    "BuGaoYu": "not higher than",
    "BuDiYu": "not lower than",
    "CuXiao": "Promotion",
    "ZDYXXZ3G": "Up to 3 options are allowed",
    "GouMai": "buy",

    "Gong1Jian": "Total {num}",


    //已订货商品
    "DLHCKJG": "Check prices after login",
    "M1JZ1J": "Get {presentNum} items with full {num} items",
    "CZPF_ZZCXC": "Frequent operation, retrying",
    "XSZJJXZ": "Limited time straight down",
    "YXSPM1YZ1J_": "The following products {str}, join the shopping cart to settle the discount!",
    "TiaoXingMa": "Bar code",
    "XiangGuanTuiJian": "Related suggestion",
    "YSSPBKJRGW": "Pre-sale products cannot be added to the shopping cart, non-pre-sale products have been added for you!",
    "YSSPBKLJGM": "Pre-sale products cannot be purchased immediately, please remove the pre-sale products and buy again!",
    "SPSLYW": "Incorrect quantity of goods",
    "ShangPin1QiShou": "Item {num}",
    "SPDGSLBNCG": "Product order quantity cannot exceed {num}",
    "QRYCXZSP": "Confirm to remove the selected product",
    "QRYCSYSXSP": "Confirm to remove all expired products",
    "QRYCXZSXSP": "Confirm removal of selected expired products",
    "M1YZ1J": "Free {presentNum} items with full {num}USD",
    "M1J1": "Over {price} minus {money}",
    "M1YD1Z": "{money} discount on full {price} USD",
    "DDM1YHZ": "Receive free orders over {price} USD",
    "YiHuoZeng": "Gifted",
    "ZG1YLXM1YZ1": "Repurchase {price}US Dollars to enjoy{str}",
    "ZG1JLXM1JZ1": "Repurchase {num}pieces and enjoy {str}",
    "QCSXSP": "Remove expired",
    "YiXuanShangPin": "Selected products",
    "QuJieSuan": "To settle",
    "ShiXiaoShangPin": "Expired goods",
    "DingDanGuanLi": "Order management",
    "ZhuangTai": "Status",
    "ShangPinChuKu": "Commodities out of stock",
    "DengDaiShouHuo": "Waiting for delivery",
    "PeiSongXinXi": "Shipping information",
    "FuKuanXinXi": "Payment information",
    "GDFKJL": "More payment records",
    "WuLiuFuJian": "Logistics accessories",
    "ZanWuFuJian": "No attachments",
    "YiFa": "sent",
    "YingFuZongE": "Total payable",
    "FuKuanShiJian": "Payment time",
    "ZhangHuHaoMa": "account number",
    "KeYongYuE": "Available Balance",
    "TianJiaChengGong": "Added successfully",
    "ShanChuDiZhi": "Delete address",
    "QXZSHDZ": "Please select the delivery address",
    "YiHuoZengPin": "Gifts received",
    "YiYouHui1Yuan": "{money} USD discounted",
    "QWSFPXX": "Please complete the invoice information",
    "1ZSP": "{num} products",
    "Gong": "Total",
    "Jian": "Piece",
    "ShangPinJinE": "The amount of goods",
    "YunFeiDaoFu": "Freight collect",
    "QSRZQDSHRX": "Please enter the correct consignee name",
    "QSRZQDXXDZ": "Please enter the correct detailed address",
    "DBQ_MYZDDY": "Sorry, no corresponding page was found",
    "ShouHuoChengGong": "Received successfully",
    "Hi_HuanYingNin": "Hi, welcome",
    "YiFaEWuLiu": "E-logistics",
    "NinDeDingDan": "your order",
    "ZBNBHBQ": "Cannot contain emoticons",
    "XMBNWK": "Name cannot be empty",
    "XiTongSheZhi": "System settings",
    "ZhangHaoXinXi": "Account information",
    "QingShuRuXingMing": "Please type in your name",
    "QWSZL_JZYG": "Please perfect the information, it will help you serve you better",
    "QSRZQDYXGS": "Please enter the correct email format",
    "QTXZQDCWXX": "Please fill in the correct financial information so that your partner can invoice you",
    "GXSHDZCG": "Successfully updated delivery address",
    "BCSHDZCG": "Successfully saved delivery address",
    "XinZengDiZhi": "Add address",
    "QXZYQXSCDS": "Please select the product you want to cancel",
    "QuXiaoShouCang": "Uncollect",
    "XuanZeGuiGe": "Select specifications",
    "CaiWuGuanLi": "Financial Management",
    "DuiZhangMingXi": "Reconciliation details",
    "QSRLWJL_JE": "Please enter the transaction history/amount",
    "YiGouMai1Ci": "Purchased {count} times",
    "6_1GouMai": "{time} purchase",
    "TZGGXQ": "Notice announcement details",
    "ZYXSCSP": "Only upload videos",
    "SCSPDXBYXC": "Uploaded video size should not exceed {size}",


    "YDHSP": "Ordered goods",
    "ShangPinPinPai": "Product brand",
    "ShangPinLieBiao": "Product list",
    "LingShouJia": "retail price",
    "YouHuiJia": "Ordering Price",
    "LiJiGouMai": "Buy now",
    "ZhuTu": "Main image",
    "WuXianZhi": "Unlimited",
    "XiaoJi": "Subtotal",
    "SCXZSP": "Delete",
    "QSRDDH":"Please enter your order number",
    "QSRDDH_SPMC": "Please enter the order number / product name",
    "CaoZuo": "Operating",
    "WuLiuXinXi": "Logistics information",
    "CaoZuoLeiXing": "Operation type",
    "CaoZuoRiZhi": "Operation log",
    "CaoZuoShiJian": "Operating time",
    "ZhangHuMingCheng": "account name",
    "KaiHuYinHang": "Bank account",
    "DDTJCG_XZZC": "The order is submitted successfully, now it is only the last step",
    "ZhiFuJinE": "Payment amount",
    "DZFJE": "Amount to be paid",
    "QTXYXSMB": "Fill in some instructions",
    "XZSKYHZH": "Select the receiving bank account",
    "TiShi": "prompt",
    "NHY1GDDGJ1Y": "You still have {num} orders totaling {money}",
    "QKJQHCKJXX": "Continue to place orders after the arrears are settled",
    "HDDDXX": "Check order information",
    "BJSHDZ": "Edit shipping address",
    "TongZhiGongGao": "Announcement",
    "YinHangZhangHao": "Bank Account",
    "XiuGaiShouJi": "Modify phone",
    "LXRXX": "contact information",
    "GSCWXX": "Company Financial Information",
    "QSRKHYHMCJ": "Please enter the account opening bank name and account opening branch",
    "QSRYHZH": "Please enter bank account",
    "PiLiangCaoZuo": "Bulk operations",
    "GouMaiJiLu": "Purchase History",
    "TongZhiBiaoTi": "Notification title",
    "FaBuShiJian": "release time",
    "XiaoXiLeiXing": "Message type",
    "XiaoXiNeiRong": "Message content",
    "JieShouShiJian": "Acceptance time",
    "QuanBuPinPai": "All brands",
    "WXSYS_DHGBJ": "Scanning on WeChat makes ordering more convenient",
    "GZWXGZH": "Follow WeChat Official Account",
    "FuWuReXian": "Service Hotline",
    "SouSuoLiShi": "search history",
    "QuanBuShanChu": "delete all",
    "NH_HYLDYSDH": "Hello, welcome to {company}!",
    "ZSJDH": "Order on mobile",
    "SYS_GZWXGZH": "Sweep, follow WeChat public account",
    "GuanZhuWoMen": "Follow us",
    "QBSPFL": "All Categories",
    "QuPeiDeng": "Go with lights",
    "KuanXiang": "Money",
    "QSRSPMC_GG_": "Please enter the product name/specification/number/first letter",
    "NH_HYLDYSD": "Hello, welcome to Yunshang Order Mall!",
    "ReXiao": "sell like hot cakes",
    "20Tiao_Ye": "20 items/page",
    "ShiChangJia_": "Market price:",
    "$": "$",
    "_134.10Qi": "From $100",
    "HJ_BHYF": "Total (Excluding freight):",
    "QDSCXZSP_": "Are you sure you want to delete the selected product?",
    "QDQCXJSP_": "Are you sure you want to clear the items?",
    "DDTJCG_XZZ": "The order is submitted successfully, and it is only the last step!",
    "XuanZeZhangHu": "Choose an account",
    "DaiZhiFu": "To be paid",
    "CaiGouDingDan": "purchase order",
    "DingDanXinXi": "Order information",
    "TuiChu": "drop out",
    "KaiHuMingCheng": "AA",
    "QSRKHMC": "Please enter the account opening name",
    "_QTXZQDCWX": "(Please fill in the correct financial information so that your partner can invoice you)",
    "ShouHuoRen": "Receiver",
    "SWMRSHDZ": "Set as default shipping address",
    "TianJiaDiZhi": "Add address",
    "Gong4GeGuiGe": "4 specifications",
    "Zhi": "only",
    "Kuai": "Piece",
    "Tou": "head",
    "Xiang": "box",
    "Ping": "bottle",
    "KaiShiRiQi": "start date",
    "JieShuRiQi": "End date",
    "YiXuan": "Selected,",
    "ZhiFuZhuangTai": "Payment status,",
    "QDLCKJG": "Please log in to check the price,",
    "QDLHCZ": "Please log in to operate,",
    "YYZH_QDL": "Have an account, log in,",
    "ZCXZH": "Register a new account,",
    "YZMBZQ": "Incorrect verification code,",
    "ZhiFuJiLu": "Payment records,",


    // route-fetch
    "XiaXianTongZhi": "Offline notification,",
    "QNJKFK_WMJKWNCL": "Please pay as soon as possible and we will process the order for you as soon as possible!",
    "NDZHYZQTSB": "Your account has been logged in on other devices, please log in again. If not operated by yourself, please change the password in time,",


//缺失内容
    "ShangPinMingCheng": "Product name",
    "ShangPinMingChen": "Product name",
    "DingGouShuLiang": "Order quantity",
    "TuiHuoShuLiang": "Quantity returned",
    "QSRTHNR": "Please enter the return content",
    "SCTHSM": "Upload return instructions",
    "ShenQingShiJian": "application time",
    "ShenQingJinE": "Application Amount",
    "TuiHuoYuanYin":"The return reason",
    "TuiHuoShuoMing":"Return instructions",
    "TuiDanHao":'Back order',
    "SXCZ_XDCG":"Credit enough, successful order",
    "SXEDBZ_FKHFH":"Insufficient credit line, delivery after payment",
    "CGJKZQ_FKFH":"After the borrowing period, payment delivery",
    "DDTJCG_XZZCZHYB":"The order was submitted successfully. Now there is only one last step left!",
    "QNZ_NWCFK_FZ":"Please complete the payment within {time}, otherwise the order will be cancelled automatically.",

    "DPYDY_QSHX":"The shop is closed. Please place your order later",
    "DaYangShiJian":"Closing time",
    'IMEI':'IMEI',

    "FanKuanYuE":"Refund the balance",
    "FanKuanZhiFu":"Refund payment",
    "FKYEBZ":"The refund balance is insufficient",
    "FanKuanZhangHu": "Rebates account",
    "HaiXuZhiFu": "We also have to pay {price}",

    "FaPiaoMingXi":"The invoice details",
    "FaPiaoZhuShou": "Invoice assistant",
    "WoDeFaPiao": "My invoice",
    "FaPiaoXiangQing": "Details on the invoice",
    "KaiPiaoShiJian": "The time of make out an invoice",
    "DaiKaiFaPiao": "For invoice",
    "DKFPJE": "Amount to be invoiced",
    "KaiPiaoJinE": "invoice amount",
    "GuanLianDanHao": "Associated number",
    "ChuangJianShiJian": "Creation time",
    "ZhiFuPingZheng": "Payment voucher",
    "QSRZQNR": "Please enter the correct content",
    "QSRTDH_SPMC": "Please enter the return number/product name",
    "ShangPinRuKu": "Commodity warehousing",
    "TuiKuanChuLi": "A refund processing",
    "YuanDingDanHao": "The original order number",
    "TuiHuoXinXi": "Return information",
    "TuiKuanXinXi": "The refund information",
    "TuiKuanZhuangTai": "The refund status",
    "ZWTKJL": "No refund record",
    "GDTKJL": "More Refund Records",
    "XSQBDZ":"Display all addresses",
    "DDDKFQRBJQSH": "Please wait for confirmation of the quotation",
    "BNSRDYHDTSZF": "Special characters such as single quotes cannot be entered",
    "THSLBNWK": "The returned quantity cannot be empty",
    "BZBNBHBQ": "Remarks should not contain emoticons",
    "THSLBKDYKTHSL": "The number of returns is greater than the number of returnable items",

    "DDM_YZ_J":"Free {number} items on full {target} order",
    "M_JZ_J":"Free {number} items full of {target} items",
    "M_JJZ_Y":"Full {target} item reduced to {money} yuan",
    "YDHJJZ_Y":"The original order price was reduced to {price}",
    "M_JD_Z":"Fold {percent} full {number}",
    "YDHJD_Z":"The original order price is discounted by {percent}",

    // 优惠券
    "GXNHQ_Z_Y_": "Congratulations on getting {name} {price} {name} ticket",
    "QBLX": "All types",
    "QLQZX": "Go to the coupon collection center",
    "YLYH": "Received discount",
    "MZTJJKSY": "Can be used if conditions are met",
    "GXNHD_Z_YHQ": "恭喜您获得{num}张优惠券",
    "G_Y": "共{price}元",


    "PLXD": "Batch order placement",
    "FHJL": "Shipping Record",
    "DDTJ": "Order statistics",
    "DDGL": "Order management",
    "JKWFH": "Only look at undelivered items",
    "DDS": "Number of orders",
    "DGSPS": "Number of ordered products",
    "THSPS": "Number of returned products",
    "YFHSPS": "Number of shipped items",
    "YFHSPJE": "Amount of goods shipped",
    "WFHSPJE": "Number of Unshipped Items",
    "WFH": "Not shipped",
    "YGJ": "Pre estimation",
    "GGBM": "Specification code",
    "SLHJ": "Total quantity",
    "JEHJ": "Total amount",

    "QPLKY": "All categories available",
    "KYCXHDDJSY": "Can be used in conjunction with promotional activities",
    "BKDKYF": "Non deductible freight charges",
    "SYHBYTH": "Not refundable after use",

    "TiJi": "Volume",
    "ZhongLiang": "Weight",
    "ZongTiJi": "Total Volume",
    "ZongZhongLiang": "Total Weight",
    "QSRDHDCKDWLDHSJRSJH": "Please enter the order number/outbound tracking number/logistics tracking number/recipient/phone number",

    "CKDH": "Delivery note number",
    "WLDZT": "Logistics order status",
    "WLDH": "Logistics tracking number",
    "SJR": "Recipients",
    "WFHSPS": "Número de productos no enviados",
    "RMBJE":"RMB Amount",
};
export default en_US
